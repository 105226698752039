import cn from 'classnames';
import React, {useRef, useState, useEffect} from 'react';

import Grid, {Col} from '@/components/base/layouts/utils/Grid/Grid';
import Image, {type ImageProps} from '@/components/base/elements/Image/Image';
import WistiaVideoPlayer from '@/components/shared/WistiaVideoPlayer';
import type {WistiaVideoPlayerProps} from '@/components/shared/WistiaVideoPlayer/types';

export interface SideBySideVideoProps {
  children: React.ReactNode;
  video?: WistiaVideoPlayerProps;
  image?: ImageProps;
  visualPosition?: 'left' | 'right';
  mode?: 'light' | 'dark';
  isExternalPausePlayEnabled?: boolean;
}

export default function SideBySideVideo({
  children,
  video,
  image,
  visualPosition = 'left',
  mode = 'light',
  isExternalPausePlayEnabled = false,
}: SideBySideVideoProps) {
  const isPositionLeft = visualPosition === 'left';
  const [externalPlayPause, setExternalPlayPause] = useState<
    'play' | 'pause' | undefined
  >(undefined);
  const wistiaWrapperRef = useRef<HTMLDivElement | null>(null);

  /**
   * This effect is used to set the externalPlayPause state based on the intersection observer because
   * the WistiaVideoPlayer is inconsistently autoplaying the videos due to its functionality where it
   * pauses all the other videos on the page when it plays a video. This behavior only occurs on pages
   * where there are videos in consecutive sections.
   */
  useEffect(() => {
    if (!isExternalPausePlayEnabled || !wistiaWrapperRef.current) return;

    const intersectionObserverCallback = (
      entries: IntersectionObserverEntry[],
    ) => {
      entries.forEach((entry) => {
        setExternalPlayPause(entry.isIntersecting ? 'play' : 'pause');
      });
    };
    const options = {
      root: null,
      rootMargin: '0% 0% 0% 0%',
      threshold: 0.0,
    };
    const observer = new IntersectionObserver(
      intersectionObserverCallback,
      options,
    );
    observer.observe(wistiaWrapperRef.current);

    return () => {
      observer.disconnect();
    };
  }, [isExternalPausePlayEnabled, setExternalPlayPause]);

  return (
    <Grid className="items-center">
      <Col
        span={{sm: 8, md: 6}}
        start={{md: isPositionLeft ? 1 : 7}}
        className={cn(isPositionLeft ? 'md:order-1' : 'md:order-2')}
      >
        {video && video.videoId ? (
          <div ref={wistiaWrapperRef}>
            <WistiaVideoPlayer
              externalPlayPause={externalPlayPause}
              {...video}
            />
          </div>
        ) : (
          image && (
            <div className="aspect-square">
              <Image className="w-full h-full object-cover" {...image} />
            </div>
          )
        )}
      </Col>
      <Col
        span={{sm: 8, md: 5}}
        start={{md: isPositionLeft ? 8 : 1}}
        className={cn(isPositionLeft ? 'md:order-2' : 'md:order-1')}
      >
        {React.Children.map(children, (child) => {
          const childProps = {mode: mode};
          return React.isValidElement(child)
            ? React.cloneElement(child, childProps)
            : child;
        })}
      </Col>
    </Grid>
  );
}
